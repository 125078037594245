@import '../../../../resources/colors-and-fonts';

.create-delivery-area-component {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .delivery-area-form {
    max-width: 320px;
    margin-right: auto;
  }

  .delivery-area-toggle {
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;

    &:hover {
      cursor: pointer;
    }
  }

  .content-wrapper {
    display: flex;
    height: calc(100vh - 57px);

    .side-wrapper {
      position: relative;
      margin-bottom: 10px;

      .menu-wrapper {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 100%;
        align-items: center;
        margin: 0 10px 10px 10px;
        padding: 10px 10px 0 10px;
        background-color: $tile_background_color;
        color: $lighter_text;
        box-shadow: $default_box_shadow;
        box-sizing: border-box;
        z-index: 20;


        .menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          color: $lighter_text;
          overflow-x: hidden;
          flex-grow: 1;
          background-color: $tile_background_color;
        }
      }

      .controls-wrapper {
        position: absolute;
        z-index: 10;
        color: $lighter_text;
        top: 0;
        left: 330px;

        .hex-level-select {
          display: flex;
          text-align: center;
          margin-top: 60px;

          .hex-value-wrapper {
            display: flex;
            flex-direction: column;

            .hex-arrow {
              display: flex;
              justify-content: center;
              color: $dark_text;
              font-size: 30px;

              &.disabled {
                visibility: hidden;
              }

              &:hover {
                cursor: pointer;
                color: $selection_color;
              }
            }

            .hex-value {
              background-color: $tile_background_color;
              height: 32px;
              width: 32px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: $font_weight_bold;
              box-shadow: $default_box_shadow;
            }
          }

          .hex-warning {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $warning_color;
            margin-left: 5px;
            font-size: 35px;
          }
        }
      }
    }

    .delivery-area-map-wrapper {
      position: relative;
      flex-grow: 1;
    }
  }
}
