@import '../../../../resources/colors-and-fonts';

.storage-add {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .delivery-area-form {
    margin-right: auto;
    max-width: 320px;
  }
}
