@import '../../../../resources/colors-and-fonts';

.range-picker-wrapper {
  position: relative;
  width: 100%;
  color: $lighter_text;

  .date-picker-input {
    display: flex;
    align-items: center;
    cursor: pointer;

    .dates-wrapper {
      display: flex;
      align-items: center;
    }

    .arrow-icon {
      font-size: 30px;
      cursor: pointer;
    }

    .icon-keyboard-arrow-left {
      margin-right: 20px;
    }

    .icon-keyboard-arrow-right {
      margin-left: 20px;
    }

    .icon-event {
      font-size: 18px;
      margin-right: 10px;
    }

    .dates {
      font-size: 16px;
    }

    .calendar-hover {
      &:hover {
        color: $selection_color;
      }
    }
  }

  .picker-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 31px;
    left: -145px;
    z-index: 10;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: $tile_background_color;
    padding: 10px;
    width: 663px;
    box-sizing: border-box;

    .calendar-wrapper {
      display: flex;

      .input-wrapper {
        max-width: 322px;

        .calendar-label {
          color: $tile_border_color;
          font-size: 14px;
          margin-left: 5px;
        }
      }

      .start-date {
        margin-right: 10px;
      }
    }

    .calendar-footer {
      display: flex;

      .button {
        border: 1px solid $kashmir_blue;
        padding: 10px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          background-color: $selection_color;
          border-color: $selection_color;
        }
      }

      .cancel-button {
        margin-left: auto;
        margin-right: 10px;
      }
    }

    // sass-lint:disable class-name-format
    .DayPicker-Day--disabled {
      color: $tile_border_color;
    }

    .DayPicker-Weekday {
      text-transform: capitalize;
    }

    .DayPicker-Caption {
      text-transform: capitalize;
    }

    .DayPicker-Month {
      border-collapse: separate;
    }

    .DayPicker-WeekNumber {
      outline: none;
    }

    .DayPicker-Day {
      outline: none;
      border: 1px solid transparent;
      border-radius: 0;

      &:not(.DayPicker-Day--disabled) {
        &:not(.DayPicker-Day--selected) {
          &:not(.DayPicker-Day--outside) {
            &:hover {
              background-color: $selection_color;
            }
          }
        }
      }
    }

    .DayPicker-Day--today {
      color: $error_color;
    }

    .DayPicker-Day--selected {
      &:not(.DayPicker-Day--disabled) {
        &:not(.DayPicker-Day--outside) {
          background-color: $selection_color;
        }
      }
    }
  }
}
