@import '../../../../resources/colors-and-fonts';

.planning-page-per-courier {
  display: flex;
  height: 100%;
  position: relative;

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 20px);
    margin: 10px;
    width: 400px;
    padding: 10px 10px 0 10px;
    background-color: $tile_background_color;
    color: $lighter_text;
    z-index: 1;
    box-shadow: $default_box_shadow;
    box-sizing: border-box;

    .selection-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .select {
        width: 100%;
      }

      .label {
        color: $lighter_text;
        font-weight: $font_weight_bold;
        margin-right: 10px;
        min-width: 70px;
      }
    }

    .add-new-plan-button {
      background-color: $selection_color;
      padding: 10px;
      text-align: center;
      transition: 0.3s;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .menu {
      display: flex;
      width: 100%;
      background-color: $tile_background_color;
      flex-grow: 1;
      overflow-x: hidden;
      box-sizing: border-box;

      .table-search {
        padding: 0;
        margin-bottom: 10px;

        .search {
          width: 100%;
          height: 36px;
          box-sizing: border-box;
        }
      }
    }
  }

  .empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 250px;

    .icon {
      color: $lighter_text;
    }
  }

  .map-wrapper {
    flex-grow: 1;
    padding: 0;
    height: 100%;
    box-sizing: border-box;

    .plan-preview {
      height: 100%;
    }

    .mapboxgl-ctrl {
      a {
        &:last-child {
          display: none;
        }
      }
    }
  }
}
