@import '../../../../resources/colors-and-fonts';

.mode-button {
  position: relative;
  min-width: 32px;
  width: fit-content;
  min-height: 32px;
  background-color: $tile_background_color;
  color: $lighter_text;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  box-shadow: $default_box_shadow;
  box-sizing: border-box;

  &:hover {
    color: $selection_color;
  }

  &.active {
    color: $selection_color;
  }

  .icon {
    font-weight: $font_weight_bold;
  }

  .menu {
    position: absolute;
    background-color: $background_color;
    z-index: 100;
    color: $lighter_text;
    box-shadow: $default_box_shadow;

    li {
      cursor: pointer;

      &:hover {
        color: $selection_color;
      }
    }

    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;

      &:hover {
        color: $selection_color;
      }

      .selection-label {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

  .menu-vertical {
    left: calc(100% + 10px);
    top: 0;
  }
}
