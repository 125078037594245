@import '../../../../resources/colors-and-fonts';

.region-selection-wrapper {
  position: relative;
  height: 100%;

  .chart-wrapper {
    position: absolute;
    bottom: 0;
    left: 320px;
    width: calc(100% - 320px);
    height: 350px;
    z-index: 100;
  }

  .main-content {
    position: relative;
    height: calc(100vh - 55px);
    display: flex;

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      height: calc(100% - 10px);
      padding: 10px;
      width: 300px;
      margin: 0 10px 10px 10px;
      background-color: $tile_background_color;
      color: $lighter_text;
      z-index: 15;
      box-sizing: border-box;
      box-shadow: $default_box_shadow;
      align-items: center;
      flex-shrink: 0;

      .menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
        overflow-x: hidden;
        color: $lighter_text;
        z-index: 10;

        .clustering-menu {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 100%;
          margin-bottom: 10px;
        }

        .mily-button {
          margin-left: 0;
          margin-bottom: 10px;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
        }

        .table-wrapper {
          display: flex;
          background-color: $tile_background_color;
          flex-grow: 1;

          .table-search {
            padding: 0;
            margin-bottom: 10px;

            .search {
              width: 100%;
              height: 36px;
              box-sizing: border-box;
            }
          }
        }
      }
    }

    .map-wrapper {
      flex-grow: 1;
      padding: 0;
      height: 100%;
      box-sizing: border-box;

      .selections {
        position: absolute;
        z-index: 10;
        color: $light_text;
        width: 320px;
        margin: 10px;
        padding: 10px;
        background-color: $tile_background_color;
        box-shadow: $default_box_shadow;

        .select-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          background-color: $tile_background_color;

          &:last-of-type {
            margin-bottom: 0;
          }

          .select {
            flex-grow: 1;
          }

          .label {
            color: $light_text;
            min-width: 60px;
            margin-right: 10px;
            font-weight: $font_weight_bold;
          }

          .region-color {
            width: 30px;
            height: 30px;
            margin-left: 10px;
          }
        }
      }

      .controls-wrapper {
        position: absolute;
        z-index: 10;
        color: $lighter_text;
        margin-left: 10px;
      }

      .save-default {
        position: absolute;
        right: 10px;
        margin-top: 10px;
        padding: 5px 10px;
        z-index: 10;
        color: $lighter_text;
        background-color: $kashmir_blue;
        opacity: 0.7;

        &:hover {
          cursor: pointer;
        }
      }


      .regions-map-wrapper {
        position: relative;

        .mapboxgl-ctrl {
          a {
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}

.mapboxgl-popup-content {
  background-color: $tile_background_color;
}
