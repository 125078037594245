@import '../../resources/colors-and-fonts';

.real-time {
  display: flex;
  height: 100vh;

  .filter-section {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    box-sizing: border-box;

    z-index: 10;
    width: 500px;
    padding: 10px;


    .label {
      min-width: 110px;
      color: $lighter_text;
      font-weight: $font_weight_bold;
    }

    .selections-wrapper {
      padding: 10px 10px 0 10px;
      background-color: $tile_background_color;
      box-shadow: $default_box_shadow;

      .team-selection-wrapper {
        display: flex;
        align-items: center;

        .select {
          width: 100%;
        }
      }

      .courier-selection-wrapper {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }


      .info-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .data-section {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .data-cards {
        display: flex;
        flex-wrap: wrap;
        max-width: 500px;
      }
    }

    .logs-types-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      color: $lighter_text;
      font-size: 16px;

      .tab-button {
        font-weight: $font_weight_bold;
        display: flex;
        width: 50%;
        justify-content: left;
        padding: 10px;

        &:hover {
          cursor: pointer;
        }

        .icon {
          font-size: 22px;
          margin-right: 10px;
        }

        .location-text {
          margin-top: -1px;
        }
      }

      .active {
        background-color: $background_color;
        color: $selection_color;
      }
    }

    .shipments-wrapper {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      background-color: $background_color;
      height: 100%;
      overflow: auto;

      .filter-wrapper {
        display: flex;
        margin: 5px;

        .default-input {
          border-radius: 0;
          border: 1px solid $kashmir_blue;
          font-size: 16px;
          padding: 5px 5px 5px 5px;
          width: 100%;
          box-sizing: border-box;
          background-color: $tile_background_color;
          color: $light_text;
          font-family: $font_family;
          font-weight: $font_weight_normal;

          &[disabled] + .input-label {
            opacity: .5;
          }

          &:disabled {
            opacity: .5;
          }

          &:focus {
            outline: none;
            border-color: $selection_color;
          }

          &:-webkit-autofill {
            &:hover,
            &:focus,
            &:active {
              -webkit-box-shadow: 0 0 0 30px $tile_background_color inset;
              -webkit-text-fill-color: $light_text;
            }
          }
        }
      }

      .shipment-list-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $background_color;
        overflow: auto;
        padding: 10px 5px 10px 10px;
      }
    }

    .log-header {
      background-color: $background_color;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: $lighter_text;
      padding: 10px;
      font-size: 16px;

      .timestamp-header {
        flex-grow: 1;
        flex-basis: 50px;
      }

      .distance-header {
        width: 60%;
      }
    }

    .log-wrapper {
      display: flex;
      flex-direction: column;
      background-color: $background_color;
      overflow: auto;
      color: $lighter_text;
      padding: 0 10px 10px 10px;

      .simple-bar {
        .log {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          margin: 5px 0;

          &:hover {
            color: $selection_color;
            cursor: pointer;
          }

          .timestamp {
            width: 40%;
          }

          .distance {
            width: 60%;
          }
        }
      }
    }

  }

  .map-section {
    flex-grow: 1;
  }
}
