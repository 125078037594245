@import '../../../../resources/colors-and-fonts';

.radio-button-group {
  display: flex;

  .radio-title {
    font-weight: $font_weight_normal;
    font-size: 16px;
  }

  .radio-option {
    display: flex;
    font-size: 16px;
    align-items: center;

    .value-name {
      font-weight: $font_weight_normal;
    }

    input {
      margin-top: 0;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
}
