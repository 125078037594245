@import '../../../../../resources/colors-and-fonts';

$animation: all 0.5s ease-out;

.policy-form {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;

  .form-input-wrapper {
    width: 330px;
    max-width: 350px;

    .text-input {
      .input-label {
        color: $lighter_text;
        font-weight: $font_weight_bold;
      }
    }
  }

  .page-group {
    display: flex;
    flex-direction: row;
    background-color: $tile_background_color;
    padding: 10px;
    border-bottom: 2px solid $kashmir_blue;

    .group-name {
      width: 120px;
    }

    .form-input-wrapper {
      width: 250px;
      max-width: 350px;

      .input-label {
        color: $lighter_text;
        font-weight: $font_weight_bold;
      }
    }

    .pages-wrapper {
      margin-left: 20%;
      margin-top: 40px;
      flex-grow: 2;
      flex-basis: 100px;
      transition: $animation;

      .form-input-wrapper {
        width: 700px;
        max-width: 700px;

        .input-label {
          color: $light_text;
          font-weight: $font_weight_bold;
        }
      }

      .visible {
        display: flex;
        flex-direction: column;
      }

      .hidden {
        height: 0;
        overflow: hidden;
      }

      .features-wrapper {
        margin-left: 40px;
        margin-bottom: 20px;
        margin-top: 10px;

        .form-input-wrapper {
          width: 660px;
          max-width: 660px;

          .input-label {
            color: $light_text;
            font-weight: $font_weight_normal;
          }
        }
      }
    }

    .page-title {
      color: $lighter_text;
      margin-bottom: 10px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

}
