@import '../../../../resources/colors-and-fonts';

.form-input-wrapper {
  position: relative;

  .form-label {
    width: fit-content;
    order: 1;
    font-weight: $font_weight_normal;
    font-size: 16px;
    color: $light_text;
    margin-bottom: 5px;
  }

  .input-label {
    margin-bottom: 5px;
  }

  .text-input {
    display: flex;
    flex-direction: column;

    .default-input {
      order: 2;
      border-radius: 0;
      border: 1px solid $kashmir_blue;
      font-size: 16px;
      padding: 5px;
      width: 100%;
      box-sizing: border-box;
      background-color: $tile_background_color;
      color: $light_text;
      font-family: $font_family;
      font-weight: $font_weight_normal;

      &[disabled] + .input-label {
        opacity: .5;
      }

      &:disabled {
        opacity: .5;
      }

      &:focus {
        outline: none;
        border-color: $selection_color;
      }

      &:-webkit-autofill {
        &:hover,
        &:focus,
        &:active {
          -webkit-box-shadow: 0 0 0 30px $tile_background_color inset;
          -webkit-text-fill-color: $light_text;
        }
      }
    }
  }

  .checkbox-input {
    display: flex;
    align-items: center;

    .checkbox {
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }

    .form-label {
      margin-bottom: 0;
    }
  }

  .checkbox-label {
    font-weight: $font_weight_normal;
    font-size: 16px;
    color: $light_text;

    .highlighted {
      color: $warning_color;
    }
  }

  .toggle-input {
    display: flex;
    align-items: center;

    .form-label {
      order: 0;
      margin-right: auto;
      margin-bottom: 0;
    }

    .react-toggle--checked {
      .react-toggle-track {
        background-color: $selection_color;
      }

      .react-toggle-thumb {
        border: 0;
      }
    }
  }

  &.wrapper-error {

    .default-input {
      border-color: $error_color;
    }
  }
}
