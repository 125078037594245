@import '../../../../resources/colors-and-fonts';

.route-analysis-map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .route-toggle-wrapper {
    position: absolute;
    top: -5px;
    left: 5px;

    .mode-button {
      z-index: 15;

      &:hover {
        color: $selection_color;
        cursor: pointer;
      }
    }
  }

  .pin-legend-wrapper {
    z-index: 15;
    position: absolute;
    bottom: 12px;
    left: 5px;
  }
}
