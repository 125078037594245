@import '../../../../resources/colors-and-fonts';

.courier-analysis-map-wrapper {
  height: 100%;
  position: relative;

  .geo-filter {
    display: flex;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 10px;
    height: 40px;
    padding: 0 0 0 7px;
    background-color: $tile_background_color;
    color: $lighter_text;
    z-index: 10;

    .tooltip {
      text-align: left;
      line-height: 1.5;
    }

    &:hover {
      color: $selection_color;
      cursor: pointer;
    }

    &.active {
      color: $selection_color;
    }

    .icon-wrong-location {
      font-size: 25px;
      margin-right: 5px;
    }

    .number-of-missed-stops {
      display: flex;
      align-items: center;
      margin: 0 0 0 3px;
      padding: 0 7px;
      height: 40px;
      background-color: $selection_color;
      color: $lighter_text;
    }
  }

  .heat-map-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    z-index: 10;
    background-color: $tile_background_color;
    color: $lighter_text;

    &:hover {
      color: $selection_color;
      cursor: pointer;
    }

    &.active {
      color: $selection_color;
    }
  }

  .small-italic {
    font-style: italic;
    font-size: small;
    text-transform: none;
  }

  .marker {
    background-image: url('../../../../resources/pin-thin.svg');
    image-rendering: pixelated;
    margin-top: -12px;
    background-size: contain;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .shipments-wrapper {
    align-items: center;
    display: flex;
    margin-right: 0;

    .icon {
      font-size: 20px;
    }
  }

  .padding {
    padding-left: 3px;
  }
}
