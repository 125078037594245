@import '../../../resources/colors-and-fonts';

.time-picker-wrapper {
  padding-bottom: 5px;
  width: auto;

  .label-text {
    padding-bottom: 5px;
  }

  .text-input {
    display: flex;
    flex-direction: column;
    width: auto;

    input {
      &[type='time'] {
        &::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    }

    .default-input {
      border-radius: 0;
      border: 1px solid $kashmir_blue;
      font-size: 16px;
      padding: 5px;
      width: fit-content;
      box-sizing: border-box;
      background-color: $tile_background_color;
      color: $light_text;
      font-family: $font_family;
      font-weight: $font_weight_normal;

      &[disabled] + .input-label {
        opacity: .5;
      }

      &:disabled {
        opacity: .5;
      }

      &:focus {
        outline: none;
        border-color: $selection_color;
      }

      &:-webkit-autofill {
        &:hover,
        &:focus,
        &:active {
          -webkit-box-shadow: 0 0 0 30px $tile_background_color inset;
          -webkit-text-fill-color: $light_text;
        }
      }
    }

    .error {
      font-size: 12px;
      line-height: 1;
      min-height: 1em;
      margin: 5px 0 0 0;
      padding-left: 5px;
      color: $error_color;
      font-weight: $font_weight_normal;
    }
  }

  &.wrapper-error {

    .default-input {
      border-color: $error_color;
    }
  }
}
