@import '../../../../resources/colors-and-fonts';

.no-data {
  color: $lighter_text;
  margin-bottom: auto;
  margin-top: auto;
}

.day-of-the-week {
  color: $lighter_text;
  margin-bottom: 5px;
  margin-top: 0;
}

.bar-chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  box-shadow: $default_box_shadow;
  color: $lighter_text;
  font-family: $font_family;
  background-color: $tile_background_color;

  .info-dialog-wrapper {
    align-self: flex-start;
  }
}
