@import '../../../../resources/colors-and-fonts';

.region-analysis-map-wrapper {
  height: 100%;
  position: relative;

  .mapboxgl-marker :hover {
    cursor: pointer;
  }
}
