@import '../../../../resources/colors-and-fonts';

.circle-wrapper {
  position: absolute;
  top: -8px;
  right: -13px;
  z-index: 15;

  .dot {
    background-color: $kashmir_blue;
    border-radius: 50%;
    display: inline-block;
    color: $lighter_text;
    text-align: center;
    font-family: $font_family;
    font-weight: $font-weight_bold;
    padding: 0 3px 0 3px;

    &.single {
      padding: 0 5px 0 5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.large {
    right: -20px;
  }

  &.very-large {
    right: -25px;
  }
}

