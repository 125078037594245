@import '../../../../resources/colors-and-fonts';

.route-preview {
  position: relative;
  height: 100%;

  .route-preview-content {
    position: relative;
    height: calc(100vh - 55px);
    display: flex;


    .menu-wrapper {
      display: flex;
      flex-direction: column;
      height: calc(100% - 10px);
      padding: 10px 10px 0 10px;
      width: 300px;
      margin: 0 10px 10px 10px;
      background-color: $tile_background_color;
      color: $lighter_text;
      z-index: 1;
      box-sizing: border-box;
      box-shadow: $default_box_shadow;
      align-items: center;
      flex-shrink: 0;

      .menu {
        display: flex;
        width: 100%;
        background-color: $tile_background_color;
        flex-grow: 1;
        overflow-x: hidden;
        box-sizing: border-box;

        .table-wrapper {
          display: flex;
          background-color: $tile_background_color;
          flex-grow: 1;

          .table-search {
            .search {
              width: 100%;
              box-sizing: border-box;
            }
          }

          .table-row {
            padding-bottom: 3px;
          }
        }
      }
    }

    .map-wrapper {
      flex-grow: 1;
      padding: 0;
      height: 100%;
      box-sizing: border-box;

      .plan-preview {
        height: 100%;

        .mapboxgl-ctrl {
          a {
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
  }
}
