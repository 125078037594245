@import '../../../../resources/colors-and-fonts';

.step-info-header {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px;
  background-color: $tile_background_color_with_opacity;
  z-index: 10;

  .shipments-covered-info {
    display: flex;
    font-size: 20px;
    color: $lighter_text;
    margin-right: auto;

    .not-covered {
      color: $error_color;
      margin-right: 5px;
    }

    .covered {
      color: $success_color;
      margin-right: 5px;
    }
  }

  .message {
    color: $lighter_text;
    margin-right: auto;
    font-weight: $font_weight_bold;
    font-size: 20px;
  }

  .button {
    padding: 5px 10px;
    color: $lighter_text;
    margin-left: 10px;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .back {
    background-color: $kashmir_blue;
  }

  .next {
    padding: 5px 10px;
    color: $lighter_text;
    background-color: $selection_color;
  }

}
