@import '../../../../resources/colors-and-fonts';


.da-table-wrapper {
  display: flex;
  background-color: $tile_background_color;
  flex-grow: 1;

  .table-search {
    padding: 0;
    margin-bottom: 10px;

    .search {
      width: 100%;
      height: 36px;
      box-sizing: border-box;
    }
  }
}

