.courier-select {
  width: 100%;
  display: flex;
  align-items: center;

  .select {
    width: 100%;
    min-height: 20px !important; // sass-lint:disable-line no-important

    .css-tlfecz-indicatorContainer { // sass-lint:disable-line class-name-format
      padding: 0 5px;
    }
  }
}
