@import '../../../resources/colors-and-fonts';

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .error-container {
    margin: 13px 0 18px 0;
    color: $error_color;
    display: none;

    &.has-error {
      border-left: 2px solid;
      display: block;
    }

  }

  .error {
    font-size: 12px;
    line-height: 1;
    min-height: 1em;
    margin: 5px 0 0 0;
    padding-left: 5px;
    color: $error_color;
    text-align: right;
    font-weight: $font_weight_normal;
  }
}
