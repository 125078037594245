@import '../../../../resources/colors-and-fonts';

.map-marker-popup {
  padding: 10px;
  font-family: $font_family;
  font-weight: $font-weight_normal;

  .row-wrapper {
    display: flex;
    align-items: center;
  }

  .sh-codes {
    flex-direction: column;
    align-items: flex-start;
    color: $light_text;

    .delivery-type {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: $font_weight_bold;
    }

    .delivery {
      color: $deliveries_color;
    }

    .pickup {
      color: $pickups_color;
    }

    .sh-code {
      display: flex;
      font-size: 17px;
    }
  }

  .description {
    color: #fff;
    font-size: 16px;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    font-style: italic;

    .pause {
      color: $warning_color;
    }
  }
}
