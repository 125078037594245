@import '../../../resources/colors-and-fonts';

.region-analysis {
  height: 100%;

  .split-screen {
    display: flex;
    flex-direction: column;
    height: 100%;

    .controls-wrapper {
      position: absolute;
      color: $lighter_text;
      top: 0;
      margin-top: 10px;
      margin-left: 10px;

      .filter-wrapper {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        background-color: $tile_background_color;
        color: $lighter_text;
        box-shadow: $default_box_shadow;

        &.extend-width {
          width: 663px;
          box-sizing: border-box;
          box-shadow: none;
        }

        .center-selection-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .label {
            min-width: 130px;
            font-weight: $font_weight_bold;
            color: $light_text;
          }

          .select {
            width: 100%;
          }
        }

        .date-range {
          display: flex;
          margin-bottom: 7px;
        }

        .title {
          flex-shrink: 0;
          font-size: 16px;
          font-weight: $font_weight_bold;
          width: 130px;
          color: $light_text;
        }

        .range-picker-wrapper {
          width: 230px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .quick-links {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          font-size: 14px;
          text-decoration: underline;

          & div {
            &:hover {
              color: $selection_color;
              cursor: pointer;
              text-decoration: underline;
              text-decoration-color: $selection_color;
            }
          }
        }
      }

      .mode-button {
        z-index: 4;

        &:first-child {
          padding: 5px 10px 5px 7px;
        }

        .button-text {
          margin-left: 10px;
          font-size: 14px;
        }

        .icon {
          font-size: 25px;
        }

        .menu {
          z-index: 4;
        }
      }
    }


    .map {
      flex-grow: 1;
      position: relative;
      transition: height 1s ease-in-out;
      overflow: hidden;

      .less-opacity {
        opacity: 30%;
      }

      .map-buttons {
        display: flex;
        position: absolute;
        bottom: 0;

        .button {
          color: $lighter_text;
          font-size: 45px;

          &.active {
            color: $selection_color;
          }

          &:hover {
            color: $selection_color;
          }

          .icon-build {
            font-size: 40px;
          }
        }
      }
    }

    .chart {
      height: 345px;
      transition: height 1s ease-in-out;
      overflow: hidden;

      &.hidden {
        height: 0;
      }
    }
  }

  .number-stops-input {
    align-items: center;
    background-color: $tile_background_color;
    color: $lighter_text;
    border-color: hsl(0, 0%, 80%);
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    width: 50px;
    height: 28px;
    padding-left: 10px;
    margin-left: 10px;
    font-size: 16px;
  }

  .button-optimise {
    text-transform: uppercase;
    border: 1px solid $tile_border_color;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    background-color: #ff7f50;

    &:hover {
      background-color: $selection_color;
      border-color: $selection_color;
    }
  }

}

// sass-lint:disable class-name-format
.Resizer {
  background: $background_color;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;

  &.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;

    &:hover {
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }
  }

  &.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;

    &:hover {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      border-color: transparent;
    }
  }

  .empty-pane-children {
    display: none;
  }
}
