@import '../../resources/colors-and-fonts';

$toolbar-height: 60px;

.app {
  display: flex;
  min-height: 100vh;
  background-color: $tile_background_color_with_opacity;
  font-family: $font_family;
  font-weight: $font_weight_normal;
  width: 100%;

  .main {
    width: 100%;

    .content {
      padding: 0;
      box-sizing: border-box;
      height: 100%;
    }
  }


  .MuiBackdrop-root { // sass-lint:disable-line class-name-format
    z-index: 100;
    color: $selection_color;
  }
}

.root {
  .sign-in-toolbar-wrapper {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;

    .signin-toolbar {
      justify-content: space-between;
      align-items: center;
      height: 50px;
      background-color: $background_color;
      opacity: 0.9;
      color: $lighter_text;
      padding: 0 10px;
      font-family: $font_family;
      box-shadow: $default_box_shadow;

      .logo {
        font-size: 24px;
      }

      .name-section {
        display: flex;

        .name {
          margin-left: 10px;
          font-size: 24px;
        }
      }
    }
  }

  .login-title {
    color: $lighter_text;
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: $font_weight_bold;
  }

  .login-footer {
    font-size: 12px;
    color: $kashmir_blue;
    margin-top: 20px;

    .reset-password {
      color: $selection_color;
      margin-left: 1px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  [data-amplify-authenticator] [data-amplify-router] {
    box-sizing: border-box;
    border: 0;
    padding: 35px 40px;
    box-shadow: $default_box_shadow;

    .amplify-heading {
      font-size: 18px;
      color: $lighter_text;
      font-weight: $font_weight_bold;
      font-family: $font_family;
    }
  }

  [data-amplify-authenticator] {
    height: 100vh;
    background-image: url('../../resources/login-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    font-family: $font_family;

    [data-amplify-form] {
      padding: 0;

      .amplify-heading {
        margin-bottom: 20px;
      }

      & input {
        border: 1px solid #e1e4ea;
        background-color: $lighter_text;
        border-radius: 0;
        font-size: 14px;
        box-sizing: border-box;
        font-weight: $font_weight_normal;
        padding: 13px;

        &:focus {
          border: 1px solid $selection_color;
        }
      }

      .amplify-label {
        color: $light_text;
        font-size: 14px;
      }

      .amplify-button {
        border-radius: 0;
      }

      .amplify-button--primary {
        width: 155px;
        align-self: flex-end;
        margin-top: 20px;
        padding: 14px 0;
        background-color: $selection_color;
        color: $lighter_text;
        font-family: $font_family;
        font-size: 12px;
        font-weight: $font_weight_bold;
        text-transform: uppercase;
      }
    }

    [data-amplify-footer] {
      padding: 0;
    }

    .amplify-button--small {
      color: $kashmir_blue;
      font-family: $font_family;
      margin-top: 20px;

      &:hover {
        background: none;
        color: $selection_color;
      }
    }
  }
}

