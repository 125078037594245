@import '../../../../resources/colors-and-fonts';

.table-search {
  background-color: $tile_background_color;

  .search {
    border: 1px solid $kashmir_blue;
    background-color: $tile_background_color;
    font-size: 16px;
    padding: 2px 8px 1px;
    color: $light_text;
    font-family: $font_family;
    font-weight: $font_weight_normal;
    min-width: 200px;

    &:placeholder-shown {
      font-style: italic;
    }

    &:focus {
      outline: none;
    }
  }
}
