@import '../../../../resources/colors-and-fonts';

.address-label-map-section {
  height: 100%;
  position: relative;

  .mapboxgl-marker :hover {
    cursor: pointer;
  }

  .mode-button {
    position: absolute;
    z-index: 10;
    bottom: 10px;
    left: 10px;
  }

  .empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 250px;

    .icon {
      color: $lighter_text;
    }
  }
}
