@import '../../../resources/colors-and-fonts';

.data-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc((100% - 20px) / 3);
  height: 156px;
  padding: 10px;
  box-shadow: $default_box_shadow;
  box-sizing: border-box;
  margin: 0 5px 0 5px;
  border: 5px solid $tile_background_color;
  background-color: $tile_background_color;

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:nth-child(4n) {
    margin-left: 0;
  }

  .title {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
    font-weight: $font_weight_bold;
    color: $light_text;
  }

  .value {
    margin-top: 5px;
    font-size: 42px;
    font-weight: $font_weight_normal;
    color: $lighter_text;

    .total-value {
      font-size: 24px;
      opacity: 0.6;
    }
  }

  .toggle {
    position: absolute;
    right: 10px;
    bottom: 10px;

    .react-toggle-track {
      width: 33px;
      height: 20px;
      border: 1px solid #cecece;
    }

    .react-toggle-thumb {
      top: 0;
      width: 20px;
      height: 22px;
      border-color: $lighter_text;
    }
  }

  .react-toggle--checked {
    &.delivery {
      .react-toggle-track {
        background-color: $deliveries_color;
      }
    }

    &.pickups {
      .react-toggle-track {
        background-color: $pickups_color;
      }
    }

    &.unsuccessful {
      .react-toggle-track {
        background-color: $warning_color;
      }
    }

    &:hover {
      &:not(.react-toggle--disabled) {
        &.delivery {
          .react-toggle-track {
            background-color: $deliveries_color;
          }
        }

        &.pickups {
          .react-toggle-track {
            background-color: $pickups_color;
          }
        }
      }
    }

    .react-toggle-thumb {
      left: 16px;
    }
  }

  .react-toggle--focus {
    .react-toggle-thumb {
      border: 0;
      box-shadow: none;
    }
  }
}
