@import '../../../resources/colors-and-fonts';

.address-labeling-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .address-labeling {
    display: flex;
    position: relative;
    flex-grow: 1;

    .menu-wrapper {
      display: flex;
      height: calc(100% - 10px);
      flex-direction: column;
      padding: 10px 10px 0 10px;
      width: 500px;
      margin: 0 10px 10px 10px;
      background-color: $tile_background_color;
      color: $lighter_text;
      overflow-x: hidden;
      box-sizing: border-box;
      z-index: 10;
      box-shadow: $default_box_shadow;

      .filter-wrapper {
        display: flex;
        flex-direction: column;
        z-index: 15;
        padding: 5px 0 5px 0;
        background-color: $tile_background_color;
        color: $lighter_text;
        width: auto;

        .center-selection-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .label {
            min-width: 130px;
            font-weight: $font_weight_bold;
            color: $light_text;
          }

          .select {
            width: 100%;
          }
        }
      }

      .menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: $lighter_text;
        overflow-x: hidden;
        z-index: 10;
        flex-grow: 1;

        .mily-button {
          margin-left: 0;
          width: 100%;
          padding: 10px;
          margin-bottom: 10px;
        }

        .table-wrapper {
          display: flex;
          background-color: $tile_background_color;
          flex-grow: 1;

          .table-search {
            padding: 0;
            margin-bottom: 10px;

            .search {
              width: 100%;
              height: 36px;
              box-sizing: border-box;
            }
          }
        }
      }
    }

    .empty-page {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 250px;

      .icon {
        color: $lighter_text;
      }
    }

    .address-labeling-map-section {
      flex-grow: 1;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
