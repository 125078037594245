.time-text {
  font-size: x-large;
}

.timeline-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  height: calc(100vh - 330px);
  margin-top: 10px;
}

.timeline-wrapper-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 330px);
}
