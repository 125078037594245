.form-dialog-component {
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 300px;

  .title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
